<template>
  <div>
    <v-alert
      v-if="checkUserSubStatus()"
      dense
      outlined
      prominent
      type="warning"
      class="mt-5"
    >
      <v-row align="center">
        <v-col class="grow">
          <strong>You can only create 4 members:</strong>
          <font>To collaborate with more members and access more features, upgrade to a paid plan.</font>
        </v-col>
      </v-row>
    </v-alert>
    <v-col class="text-right mt-5">
      <v-btn
        v-if="checkUser()"
        color="#000"
        :disabled="checkUserSubStatus()"
        @click="showForm = true"
      >
        <v-icon class="mr-1">
          mdi-account-plus-outline
        </v-icon>
        Invite
      </v-btn>
    </v-col>
    <v-tabs
      v-model="tab"
      color="#FF3700"
      background-color="#FFFFFF"
    >
      <v-tab>Active</v-tab>
      <v-tab>Invite</v-tab>
    </v-tabs>
    <v-tabs-items
      v-model="tab"
      touchless
    >
      <v-tab-item>
        <active-members-list />
      </v-tab-item>
      <v-tab-item>
        <invite-members-list />
      </v-tab-item>
    </v-tabs-items>
    <create-member
      v-if="showForm"
      @closed="showForm = false"
      @success="getMemberDetails()"
    />
  </div>
</template>

<script>
import ActiveMembersList from './ActiveMembersList.vue';
import InviteMemberList from './InviteMemberList.vue';
import CreateMember from './CreateMember.vue';
import global from '../../../mixins/global';
export default {
  name: 'MemberList',
  components: {
    'active-members-list': ActiveMembersList,
    'invite-members-list': InviteMemberList,
    'create-member': CreateMember,
  },
  mixins: [global],
  data () {
    return {
      tab: null,
      showForm: false,
    };
  },
  computed: {
    inviteMembersList () {
      return this.$store.getters['members/getInviteMembersList'];
    },
  },
  methods: {
    checkUser () {
      if (this.roleCheck('userAdmin')) {
        if (this.accessCheck('account_admin')) {
          return true;
        }
        return false;
      }
      return false;
    },
    checkUserSubStatus () {
      if (this.subscriptionCheck('trial') && this.inviteMembersList.length === 3) {
        return true;
      }
      return false;
    },
    async getMemberDetails () {
      await this.$store.dispatch('members/fetchInviteMembersList', {
        params: {
          params: {
            name: null,
            requestStatus: ['invited', 'declined', 'approved'],
          },
        },
      });
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474F;
}
.v-card__text {
  padding: 30px !important;
}
.v-alert {
    padding: 5px 15px;
}
.v-alert--outlined {
    background: #fb8a000e !important;
    border: thin solid currentColor !important;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
