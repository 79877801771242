<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account-plus
        </v-icon> Invite Member
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="First Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Last Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  class="field-style"
                  label="E-mail Address*"
                  :rules="emailRules"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="selectedAccessLevel"
                  class="field-style"
                  :items="accessLevel"
                  label="Access level*"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="selectedMemberRole"
                  class="field-style"
                  :items="membersRolesList"
                  label="Member Role*"
                  item-text="member_role"
                  item-value="id"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="country"
                  class="field-style"
                  :items="countryList"
                  label="Country*"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-switch
                  v-model="checkOrganization"
                  hint="Invite users to other organization ?"
                  persistent-hint
                  color="#FF3700"
                  :disabled="isCountrySelected"
                  class="hrw-switch"
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-combobox
                  v-model="selectedOrganization"
                  :search-input.sync="name"
                  :items="filteredSearchForOrganization"
                  single-line
                  append-icon="mdi-chevron-down"
                  label="organization Name*"
                  item-text="name"
                  item-value="id"
                  dense
                  outlined
                  :rules="organizationRules"
                  hint="For your reference - 'XYZ Builders'"
                  persistent-hint
                  :disabled="checkOrganization === false"
                  @keyup="searchForOrganization()"
                />
              </v-col>
            </v-row>
            <br>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="InviteMember()"
            >
              Invite
            </v-btn>
            <v-btn
              class="mt-2"
              color="aaa"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
  <script>
    import Constants from 'src/constants';
    import spinner from 'src/views/dashboard/component/SpinnerCentre';

    export default {
      name: 'CreateMember',
      components: {
        'centre-spinner': spinner,
      },
      data () {
        return {
          showModal: true,
          firstName: '',
          lastName: '',
          mobileNumber: '',
          loading: false,
          isFormValid: false,
          email: null,
          country: null,
          showErrorMsg: null,
          checkOrganization: false,
          selectedAccessLevel: null,
          selectedMemberRole: null,
          selectedOrganization: null,
          name: null,
          accessLevel: [
            {
              name: 'Account administrator',
              id: 'account_admin',
            },
            {
              name: 'Project administrator',
              id: 'project_admin',
            },
            {
              name: 'Project member',
              id: 'project_member',
            },
          ],
        };
      },
      computed: {
        emailRules () {
          if (this.email === null) {
            return [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'];
          } else {
            return [];
          }
        },
        userProfile () {
          return this.$store.getters['userprofile/getUserProfile'];
        },
        organizationRules () {
          if (this.checkOrganization === true) {
            if (this.name === null) {
              return [(v) => !!v || 'This field is required'];
            }
            return [];
          } else {
            return [];
          }
        },
        membersRolesList () {
          return this.$store.getters['members/getMembersRolesList'];
        },
        isCountrySelected () {
          if (this.country !== null) {
            return false;
          }
          return true;
        },
        CountryZipCodeList () {
          return this.$store.getters['userprofile/getCountryCodes'];
        },
        countryList () {
            return Object.keys(this.CountryZipCodeList).map((key) => ({ text: this.CountryZipCodeList[key], value: this.CountryZipCodeList[key] }));
        },
        organizationFromSearch () {
            return this.$store.getters['organization/getOrganizationFromSearch'];
        },
        filteredSearchForOrganization () {
          return this.organizationFromSearch.filter(item => item.external_id !== this.userProfile.organization.external_id);
        },
      },
      watch: {
        showModal: function (newval, oldval) {
          if (newval === false) {
            this.$emit('closed');
          }
        },
        checkOrganization: function (newval, oldval) {
          if (newval === false) {
            this.selectedOrganization = '';
          }
        },
      },
      async mounted () {
       await this.loadOnMount();
       this.clearOrganizationFromSearch();
      },
      methods: {
        async loadOnMount () {
          this.loading = true;
          await this.$store.dispatch('userprofile/fetchCountryCodes');
          this.loading = false;
        },
        async InviteMember () {
          if (this.$refs.form.validate() === false) {
            await this.$store.dispatch('alert/onAlert', {
              message: 'Please fill the required fields before proceeding.',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
          if (this.email === this.userProfile.email) {
            await this.$store.dispatch('alert/onAlert', {
              message: 'User with this mail id already exists',
              type: Constants.ALERT_TYPE_ERROR,
            }, { root: true });
            return;
          }
          this.loading = true;
          const userOrganization = this.userProfile.organization.external_id;
          await this.$store.dispatch('members/InviteMember', {
            details: {
              first_name: this.firstName,
              last_name: this.lastName,
              email_id: this.email,
              country: this.country,
              access_level: this.selectedAccessLevel,
              member_role_id: this.selectedMemberRole,
              invited_to: !this.selectedOrganization && !this.name ? userOrganization : (this.selectedOrganization ? this.selectedOrganization.external_id : null),
              organization_name: this.name,
            },
          }).then(response => {
            this.$store.dispatch('alert/onAlert', {
              message: 'New app user created successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.loading = false;
            this.$emit('success');
            this.showModal = false;
          }).catch(() => {
            this.loading = false;
          });
        },
        async searchForOrganization () {
        await this.$store.dispatch('organization/fetchOrganizationFromSearch', {
          params: {
            params: {
              name: this.name === '' ? null : this.name,
              country: this.country,
            },
          },
        });
      },
      clearOrganizationFromSearch () {
        this.$store.dispatch('organization/clearOrganizationFromSearch');
      },
      },
    };
  </script>
  <style scoped>
  .title-style {
    color: #37474F;
    margin-left: 21px;
    margin-bottom: 10px;
  }
  .icon-style {
    color: #37474F;
    font-size: 55px;
    margin-right: 5px;
  }
  .my-container-style {
    padding-top: 24px;
  }
  .hrw-switch {
    margin-top: -20px;
  }
  .field-style {
    margin-top: -10px;
  }
  </style>
