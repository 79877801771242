<template>
  <v-dialog
    v-model="showModal"
    max-width="700"
    persistent
  >
    <v-card
      outlined
      color="#fff"
    >
      <br>
      <h2 class="title-style">
        <v-icon class="icon-style">
          mdi-account-edit
        </v-icon> Update Invitation Member
      </h2>
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <v-container fluid>
          <v-card-text class="text-body-1 text-center">
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="firstName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="First Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="lastName"
                  class="field-style"
                  :rules="[(v) => !!v || 'This field is required']"
                  label="Last Name*"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="email"
                  class="field-style"
                  label="E-mail Address*"
                  :rules="emailRules"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-autocomplete
                  v-model="selectedAccessLevel"
                  class="field-style"
                  :items="accessLevel"
                  label="Access level*"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="selectedMemberRole"
                  class="field-style"
                  :items="membersRolesList"
                  label="Member Role*"
                  item-text="member_role"
                  item-value="id"
                  :rules="[(v) => !!v || 'This field is required']"
                  outlined
                  dense
                />
              </v-col>
            </v-row>
            <br>
            <p class="text-sm-left">
              *Indicates required field
            </p>
          </v-card-text>
          <v-card-actions>
            <v-btn
              class="mt-2"
              color="#000"
              @click="updateInvitationMember()"
            >
              Update
            </v-btn>
            <v-spacer />
            <v-btn
              class="mt-2"
              color="#ccc"
              @click="showModal = false"
            >
              <span class="black--text">Cancel</span>
            </v-btn>
          </v-card-actions>
        </v-container>
      </v-form>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
  </v-dialog>
</template>
    <script>
      import Constants from 'src/constants';
      import spinner from 'src/views/dashboard/component/SpinnerCentre';

      export default {
        name: 'EditInvitationMember',
        components: {
          'centre-spinner': spinner,
        },
        props: {
            setUpdateDetails: {
                type: Object,
                required: true,
            },
        },
        data () {
          return {
            showModal: true,
            firstName: '',
            lastName: '',
            loading: false,
            isFormValid: false,
            email: null,
            country: null,
            selectedAccessLevel: null,
            selectedMemberRole: null,
            name: null,
            accessLevel: [
              {
                name: 'Account administrator',
                id: 'account_admin',
              },
              {
                name: 'Project administrator',
                id: 'project_admin',
              },
              {
                name: 'Project member',
                id: 'project_member',
              },
            ],
          };
        },
        computed: {
          emailRules () {
            if (this.email === null) {
              return [(v) => /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'];
            } else {
              return [];
            }
          },
          membersRolesList () {
            return this.$store.getters['members/getMembersRolesList'];
          },
        },
        watch: {
          showModal: function (newval, oldval) {
            if (newval === false) {
              this.$emit('closed');
            }
          },
        },
        async mounted () {
          this.loading = true;
          await this.loadOnMount();
          this.loading = false;
        },
        methods: {
          async loadOnMount () {
            this.firstName = this.setUpdateDetails.first_name;
            this.lastName = this.setUpdateDetails.last_name;
            this.email = this.setUpdateDetails.email_id;
            this.selectedAccessLevel = this.setUpdateDetails.access_level;
            this.selectedMemberRole = this.setUpdateDetails.member_role_id !== null ? this.setUpdateDetails.member_role_id.id : null;
          },
          async updateInvitationMember () {
            if (this.$refs.form.validate() === false) {
                await this.$store.dispatch('alert/onAlert', {
                    message: 'Please fill the required fields before proceeding.',
                    type: Constants.ALERT_TYPE_ERROR,
                }, { root: true });
                return;
            }
            this.loading = true;
            await this.$store.dispatch('members/updateInviteMembersList', {
                updateDetails: {
                    first_name: this.firstName,
                    last_name: this.lastName,
                    email_id: this.email,
                    access_level: this.selectedAccessLevel,
                    member_role_id: this.selectedMemberRole,
                },
                userId: this.setUpdateDetails.id,
            }).then(() => {
                this.$store.dispatch('alert/onAlert', {
              message: 'Updated invitation member successfully.',
              type: Constants.ALERT_TYPE_SUCCESS,
            });
            this.loading = false;
            this.showModal = false;
            this.$emit('success');
            }).catch(() => {
                this.loading = false;
            });
          },
        },
      };
    </script>
    <style scoped>
    .title-style {
      color: #37474F;
      margin-left: 21px;
      margin-bottom: 10px;
    }
    .icon-style {
      color: #37474F;
      font-size: 55px;
      margin-right: 5px;
    }
    .my-container-style {
      padding-top: 24px;
    }
    .hrw-switch {
      margin-top: -20px;
    }
    .field-style {
      margin-top: -10px;
    }
    </style>
