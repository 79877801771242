<template>
  <div>
    <v-col class="text-right mt-5">
      <v-btn
        v-if="accessCheck('account_admin')"
        color="#000"
        @click="showForm = true"
      >
        <v-icon class="mr-1">
          mdi-plus-circle-outline
        </v-icon>
        Add Role
      </v-btn>
    </v-col>
    <div>
      <v-row>
        <v-col
          cols="12"
          lg="9"
          md="12"
          sm="12"
        >
          <v-card
            color="#fff"
            class="listing-access project-list-card"
          >
            <v-card-title class="filter-card" />
            <v-data-table
              :headers="headers"
              :items="accessList"
              disable-sort
              class="elevation-1 table-projects"
              mobile-breakpoint="100"
            >
              <template #item="{ item }">
                <tr>
                  <td>
                    <v-icon
                      class="mr-2"
                      color="green"
                    >
                      mdi-check-decagram-outline
                    </v-icon>{{ item.account_admin }}
                  </td>
                  <td>
                    <v-icon
                      class="mr-2"
                      color="green"
                    >
                      mdi-check-decagram-outline
                    </v-icon>{{ item.project_admin }}
                  </td>
                  <td>
                    <v-icon
                      class="mr-2"
                      color="green"
                    >
                      mdi-check-decagram-outline
                    </v-icon>{{ item.project_member }}
                  </td>
                </tr>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <v-col
          cols="12"
          lg="3"
          md="12"
          sm="12"
        >
          <div class="listing-roles">
            <v-text-field
              v-model="search"
              label="Search"
              append-icon="mdi-magnify"
              outlined
              persistent-hint
              :disabled="membersRolesList === 0"
              dense
            />
            <div
              v-if="loading"
              class="skeleton-loader"
            >
              <v-skeleton-loader type="heading, heading, heading, heading" />
            </div>
            <div v-else>
              <v-list
                v-if="filteredItems.length !== 0"
                class="member-v-list"
              >
                <v-list-item
                  v-for="(item, i) in filteredItems"
                  :key="i"
                  class="listing-buttons background-color"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{ item.member_role }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-menu
                      bottom
                      left
                      origin="center center"
                      transition="scale-transition"
                      :offset-x="true"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          dark
                          icon
                          v-bind="attrs"
                          :disabled="!accessCheck('account_admin')"
                          v-on="on"
                        >
                          <v-icon color="#3747">
                            mdi-dots-vertical
                          </v-icon>
                        </v-btn>
                      </template>

                      <v-list class="listing-buttons">
                        <v-list-item
                          link
                          @click="deleteMemberRole(item.id)"
                        >
                          <v-icon color="#C62828">
                            mdi-trash-can-outline
                          </v-icon>Remove Role
                        </v-list-item>
                        <v-list-item
                          link
                          @click="updateMemberRole(item.id, item.member_role)"
                        >
                          <v-icon>mdi-pencil-outline</v-icon>Rename Role
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-icon>
                </v-list-item>
              </v-list>
              <div
                v-else
                class="w-100 text-center"
              >
                <p>No data found</p>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <add-role
      v-if="showForm"
      @closed="showForm = false"
      @success="getMemberRoles()"
    />
    <delete-dialog
      v-if="showDeleteDialog"
      @closed="showDeleteDialog = false"
      @confirmed="confirmDelete()"
    />
    <edit-member-role
      v-if="showEditDialog"
      :role-id="roleId"
      :role-name="roleName"
      @closed="showEditDialog = false"
      @success="getMemberRoles()"
    />
  </div>
</template>

<script>
import AddRole from './AddRole.vue';
import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
import EditMemberRole from './EditMemberRole.vue';
import Constants from 'src/constants';
import global from 'src/mixins/global';
export default {
  name: 'RolesList',
  components: {
    'add-role': AddRole,
    'delete-dialog': DeleteDialog,
    'edit-member-role': EditMemberRole,
  },
  mixins: [global],
  data () {
    return {
      showForm: false,
      showEditDialog: false,
      showDeleteDialog: false,
      search: '',
      roleId: null,
      roleName: '',
      loading: false,
      headers: [
        {
          text: 'Account Administrator',
          align: 'start',
          value: 'account_admin',
        },
        { text: 'Project Administrator', value: 'project_admin' },
        { text: 'Project Member', value: 'project_member' },
      ],
      accessList: [
        {
          account_admin: 'update organization details',
          project_admin: '-',
          project_member: '-',
        },
        {
          account_admin: 'Project - all operations are possible',
          project_admin: 'Project - all operations on assigned project is possible',
          project_member: 'Project - only view option is available  on assigned projects',
        },
        {
          account_admin: 'App user-  all operations are possible',
          project_admin: 'App user- all operations are possible',
          project_member: 'App user- all operations are possible',
        },
        {
          account_admin: 'Company-  all operations are possible',
          project_admin: 'Company-  all operations are possible',
          project_member: 'Company- all operations are possible',
        },
        {
          account_admin: 'Budget, Contract, Main contract, Issue and Performance tracking -  all operations are possible',
          project_admin: 'Budget, Contract, Main contract, Issue and Performance tracking -  all operations are possible',
          project_member: 'Budget, Contract, Main contract, Issue and Performance tracking - only view option available',
        },
        {
          account_admin: 'Can invite members to Organization',
          project_admin: '-',
          project_member: '-',
        },
        {
          account_admin: 'Memebers -  all operations are possible',
          project_admin: 'Members role and access level  can be updated to same access-level or lower access-level',
          project_member: 'Members role and access level  cannot be updated',
        },
        {
          account_admin: 'Can add or remove new members to Project',
          project_admin: 'Can add or remove new members to assigned Projects',
          project_member: 'Can add or remove new members to assigned Projects',
        },
        {
          account_admin: 'Can add or remove new companies to Project',
          project_admin: 'Can add or remove new companies to assigned Projects',
          project_member: 'Can add or remove new companies to assigned Projects',
        },
        {
          account_admin: 'Project gate -   all operations are possible',
          project_admin: 'Project gate -   all operations are possible',
          project_member: 'Project gate -   all operations are possible',
        },
        {
          account_admin: 'All other functions are avilable',
          project_admin: 'All other functions are avilable',
          project_member: 'All other functions are avilable',
        },
      ],
    };
  },
  computed: {
    membersRolesList () {
      return this.$store.getters['members/getMembersRolesList'];
    },
    filteredItems () {
      if (this.membersRolesList.length !== 0) {
        const searchLower = this.search.toLowerCase();
        return this.membersRolesList.filter(item =>
          item.member_role.toLowerCase().includes(searchLower),
        );
      }
      return [];
    },
  },
  async mounted () {
    await this.getMemberRoles();
  },
  methods: {
    async getMemberRoles () {
      this.loading = true;
      await this.$store.dispatch('members/fetchMemberRoles');
      this.loading = false;
    },
    showUpdateField (id) {
      this.editableId = id;
    },
    updateMemberRole (id, name) {
      this.roleId = id;
      this.roleName = name;
      this.showEditDialog = true;
    },
    deleteMemberRole (id) {
      this.roleId = id;
      this.showDeleteDialog = true;
    },
    async confirmDelete () {
      await this.$store
        .dispatch('members/deleteMemberRole', {
          roleId: this.roleId,
        })
        .then(response => {
          this.$store.dispatch('alert/onAlert', {
            message: 'Removed this role.',
            type: Constants.ALERT_TYPE_SUCCESS,
          });
          this.getMemberRoles();
          this.showDeleteDialog = false;
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.title-style {
  color: #37474f;
}

.icon-style {
  color: #37474f;
  font-size: 45px;
}

.v-data-table::v-deep th {
  font-size: 12px !important;
  color: #37474F !important;
  font-weight: bold !important;
}

.v-data-table::v-deep td {
  font-size: 13px !important;
  color: #37474F !important;
}

.theme--light.v-data-table tbody tr:nth-of-type(even) {
  background-color: #CFD8DC;
}

.v-data-table {
  background-color: #ECEFF1
}

.my-tabs-background-style {
  background-color: #eceff1 !important;
}

.v-card__text {
  padding: 30px !important;
}

.background-color:hover {
  background: #cccccc50;
  border-radius: 5px;
}

.member-v-list {
  margin-top: -10px;
  max-height: calc(100vh - 25rem);
  overflow-y: auto;
}

.v-list {
  padding: 0px 0px;
}

.listing-buttons .v-icon.v-icon {
  padding-right: 10px;
}

.listing-roles {
  background: #ffffff;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.v-data-table >>> .v-data-table__wrapper {
  max-height: calc(100vh - 27.5rem);
  overflow-y: auto;
}

.theme--light.v-list {
  background: #ffffff;
}

.v-card {
  margin-top: 0px;
}

.v-list-item__icon {
  margin: 0px 0;
}

.skeleton-loader {
  padding: 0 10px;
}

.v-skeleton-loader >>> .v-skeleton-loader__heading {
  margin-bottom: 30px;
  height: 15px;
  background: rgba(0, 0, 0, 0.06);
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(1) {
  width: 75%;
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(2) {
  width: 50%;
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(3) {
  width: 25%;
}

.v-skeleton-loader >>> .v-skeleton-loader__heading:nth-child(4) {
  width: 60%;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
</style>
