<template>
  <div>
    <v-alert
      v-if="!roleCheck('userAdmin')"
      text
      prominent
      type="warning"
      color="red"
      border="left"
      class="alert-style"
    >
      You are not authorized to perform this action. Please contact your administrator.
    </v-alert>
    <v-card
      color="#fff"
      class="project-list-card"
    >
      <v-card-title class="filter-card">
        <v-row>
          <v-col
            cols="12"
            lg="4"
          >
            <v-text-field
              v-model="search"
              dense
              label="Search"
              append-icon="mdi-magnify"
              outlined
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="inviteMembersList"
        :search="search"
        disable-sort
        class="elevation-1 table-projects"
        mobile-breakpoint="100"
      >
        <template #item="{ item }">
          <tr>
            <td>{{ item.first_name }} {{ item.last_name }}</td>
            <td>
              <span v-if="item.email_id !== null">
                {{ item.email_id }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.invited_by.name !== null">
                {{ item.invited_by.name }}
              </span>
              <span v-else>N/A</span>
            </td>
            <td>
              <span v-if="item.organization_name !== null">
                {{ item.organization_name }}
              </span>
              <span v-else>
                {{ item.invited_to.name }}
              </span>
            </td>
            <td class="drop-down-hover">
              <v-chip
                v-if="item.member_role_id !== null"
                outlined
                small
                class="ml-2"
                color="#37474F"
                light
              >
                {{ item.member_role_id.member_role }}
              </v-chip>
            </td>
            <td class="drop-down-hover">
              <span>{{ item.access_level | convertAccessLevel }}</span>
            </td>
            <td>
              <font
                :color="getColor(item)"
                class="font-my-style"
              >
                <span>{{ convertStatus(item) }}</span>
              </font>
            </td>
            <td>
              {{ item.created_at | convertToLocal }}
            </td>
            <td>
              <v-menu
                bottom
                origin="center center"
                transition="scale-transition"
                :offset-x="true"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    fab
                    small
                    color="transparent"
                    elevation="0"
                    :disabled="isAccountAdmin()"
                    v-on="on"
                  >
                    <v-icon color="black">
                      mdi-dots-vertical
                    </v-icon>
                  </v-btn>
                </template>

                <v-list class="listing-buttons">
                  <v-list-item
                    v-if="!isAccountUser(item)"
                    link
                    @click="deleteMember(item.id)"
                  >
                    <v-icon color="#C62828">
                      mdi-trash-can-outline
                    </v-icon>
                    Delete
                  </v-list-item>
                  <v-list-item
                    v-if="!isAccountUser(item)"
                    link
                    @click="updateInvitationMember(item)"
                  >
                    <v-icon>
                      mdi-pencil
                    </v-icon>
                    Edit
                  </v-list-item>
                  <v-list-item
                    v-if="isAccountUser(item)"
                    link
                    @click="confirmAppUser(item.id, 'approved')"
                  >
                    <v-icon>mdi-account-check-outline</v-icon>
                    Approve
                  </v-list-item>
                  <v-list-item
                    v-if="isAccountUser(item)"
                    link
                    @click="confirmAppUser(item.id, 'declined')"
                  >
                    <v-icon color="#C62828">
                      mdi-account-remove-outline
                    </v-icon>
                    Decline
                  </v-list-item>
                </v-list>
              </v-menu>
            </td>
          </tr>
        </template>
      </v-data-table>
      <br>
    </v-card>
    <centre-spinner
      :loading="loading"
    />
    <delete-app-user
      v-if="showDelete"
      :title="'Delete this app user ?'"
      :message="'Action will permanently remove the  user from the list, Do you want to continue ?'"
      @closed="showDelete = false"
      @confirmed="deleteConfirmed()"
    />
    <edit-invitation-member
      v-if="showForm"
      :set-update-details="setUpdateDetails"
      @closed="showForm = false"
      @success="getInviteMembersList()"
    />
  </div>
</template>

  <script>
    import moment from 'moment';
    import spinner from 'src/views/dashboard/component/SpinnerCentre';
    import DeleteDialog from 'src/views/dashboard/component/DeleteDialog';
    import EditInvitationMember from './EditInvitationMember.vue';
    import global from 'src/mixins/global';
    import Constants from 'src/constants';

    export default {
      name: 'InviteMembersList',
      components: {
        'centre-spinner': spinner,
        'delete-app-user': DeleteDialog,
        'edit-invitation-member': EditInvitationMember,
      },
      filters: {
        convertToLocal (stringDatetime) {
          return moment(stringDatetime).local().format('Do MMMM YYYY hh:mm A');
        },
        convertAccessLevel (val) {
        if (val === 'account_admin') {
          return 'Account Administrator';
        } else if (val === 'project_admin') {
          return 'Project Administrator';
        } else {
          return 'Project Member';
        }
      },
      },
      mixins: [global],
      data () {
        return {
          search: '',
          loading: false,
          showDelete: false,
          userId: '',
          setUpdateDetails: null,
          showForm: false,
          selectedUserRole: '',
          selectedAccessLevel: '',
          headers: [
            { text: 'Name', align: 'start', value: 'first_name' },
            { text: 'Email', value: 'email' },
            { text: 'Invited From', value: 'invited_by.name' },
            { text: 'Invited To', value: 'organization.name' },
            { text: 'Member Role', value: 'member_role.name' },
            { text: 'Access Level', value: 'access_level' },
            { text: 'Status', value: 'status' },
            { text: 'Created At', value: 'created_at' },
            { text: 'More', value: 'more' },
          ],
            accessLevelList: [
              {
                name: 'Account Adminstrator',
                id: 'account_admin',
              },
              {
                name: 'Project Adminstrator',
                id: 'project_admin',
              },
              {
                name: 'Project Member',
                id: 'project_member',
              },
            ],
        };
      },
      computed: {
        inviteMembersList () {
          return this.$store.getters['members/getInviteMembersList'];
        },
        membersRolesList () {
          return this.$store.getters['members/getMembersRolesList'];
        },
        userProfile () {
          return this.$store.getters['userprofile/getUserProfile'];
        },
        usersRoles () {
          return this.$store.getters['users/getUsersRoles'];
        },
      },
      async mounted () {
        await this.accessChecker();
      },
      methods: {
        convertStatus (val) {
        if (val.status === 'invited') {
          if (val.invited_by.external_id !== this.userProfile.organization.external_id && val.invited_to.external_id === this.userProfile.organization.external_id) {
             return 'Waiting';
            } else {
             return 'Invited';
          }
        } else if (val.status === 'declined') {
          if (val.invited_by.external_id === this.userProfile.organization.external_id) {
            return 'Rejected';
           }
          return 'Declined';
        } else {
          return 'Approved';
        }
       },
       getColor (val) {
        if (val.status === 'invited') {
          if (val.invited_by.external_id !== this.userProfile.organization.external_id && val.invited_to.external_id === this.userProfile.organization.external_id) {
            return 'cornflowerblue';
          } else {
            return '';
          }
        } else if (val.status === 'declined') {
          if (val.invited_by.external_id === this.userProfile.organization.external_id) {
            return 'red';
           }
          return 'Red';
        } else if (val.status === 'approved') {
          return 'green';
        }
       },
        async accessChecker () {
          if (this.roleCheck('userAdmin')) {
            await this.getInviteMembersList();
            await this.getUsersRoles();
          }
        },
        async getInviteMembersList () {
          this.loading = true;
          await this.$store.dispatch('members/fetchInviteMembersList', {
            params: {
              params: {
                name: null,
                requestStatus: ['invited', 'approved', 'declined'],
              },
            },
          }).catch(() => {
            this.loading = false;
          });
          this.loading = false;
        },
        async getUsersRoles () {
          await this.$store.dispatch('users/fetchUsersRoles');
        },
        isAccountAdmin () {
          if (this.accessCheck('account_admin')) {
            return false;
          } else {
            return true;
          }
        },
        isAccountUser (item) {
          if (item.invited_by.external_id !== this.userProfile.organization.external_id) {
            return true;
          } else {
            return false;
          }
        },
        updateInvitationMember (data) {
          this.setUpdateDetails = data;
          this.showForm = true;
        },
        async confirmAppUser (id, status) {
          this.loading = true;
          await this.$store.dispatch('members/updateInvitedMemberStatus', {
            data: {
              status: status,
            },
            invitationId: id,
          }).then(response => {
           this.$store.dispatch('alert/onAlert', {
            message: 'Updated successfully.',
            type: Constants.ALERT_TYPE_SUCCESS,
           });
           this.getInviteMembersList();
           this.loading = false;
          }).catch(() => {
            this.loading = false;
          });
        },
        async updateUserByAccessLevel (id) {
            this.loading = true;
            await this.$store.dispatch('members/updateInviteMembersList', {
              updateDetails: {
                member_role: this.selectedUserRole,
                access_level: id,
              },
              userId: this.userId,
            }).then(response => {
              this.$store.dispatch('alert/onAlert', {
                message: 'User updated successfully.',
                type: Constants.ALERT_TYPE_SUCCESS,
              });
              this.loading = false;
            }).catch(() => {
              this.loading = false;
            });
        },
        deleteMember (id) {
          this.userId = id;
          this.showDelete = true;
        },
        deleteConfirmed () {
            this.loading = true;
            this.$store.dispatch('users/deleteUser', {
              userId: this.userId,
            }).then(response => {
              this.$store.dispatch('alert/onAlert', {
                message: 'Cancelled user Request.',
                type: Constants.ALERT_TYPE_SUCCESS,
              });
              this.getInviteMembersList();
                this.showDelete = false;
                this.loading = false;
              }).catch(() => {
                this.loading = false;
              });
            },
      },
    };
  </script>
  <style scoped>
  .title-style {
    color: #37474F;
  }
  .icon-style {
    color: #37474F;
    font-size: 45px;
  }
  .v-data-table::v-deep th {
    font-size: 12px !important;
    color: #37474F !important;
    font-weight: bold !important;
  }
  .v-data-table::v-deep td {
    font-size: 13px !important;
    color: #37474F !important;
  }
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: #CFD8DC;
  }
  .v-data-table {
    background-color: #ECEFF1
  }
  .alert-style {
    font-family: 'Times New Roman', Times, serif;
    font-size: 16px;
  }
  .v-list {
    padding: 0px 0px;
  }
  .listing-buttons .v-icon.v-icon {
    padding-right: 10px;
  }
  .drop-down-hover .button-visiblity {
    opacity: 0;
    visibility: hidden;
  }
  .drop-down-hover:hover .button-visiblity {
    opacity: 1;
    visibility: visible;
  }
  .member-v-list{
    max-height: 300px;
    overflow-y: auto
  }
  .v-card {
    margin-top: 0px!important;
  }
  .theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
    background-color: transparent !important;
  }
  .font-my-style {
  font-weight: bold;
}
.table-projects {
  border-radius: 0px !important;
  background: #fff;
  box-shadow: none !important;
  border: 1px solid #ccc;
  border-top: none;
}
.project-list-card {
  box-shadow: none !important;
}
.table-projects tbody tr:nth-of-type(even) {
  background: #fff !important;
}
.table-projects tbody tr:nth-of-type(odd):hover {
  background: #fff !important;
}
.action-btn {
  background: #fff !important;
}
.filter-card {
  border: 1px solid #ccc !important;
  padding: 15px;
}
.table-top-border{
  border: 1px solid #ccc;
  border-bottom: none;
  background: #edf6f9;
}
.info-text{
  margin-bottom: 0px !important;
}
  </style>
